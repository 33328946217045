<template>
  <layout>
    <template slot="body">
      <HeroSection
        image="agent_banner.jpg"
        :title="$t('sections.rent.heroSection.title')"
        :subtitle="$t('sections.rent.heroSection.subtitle')"
        :search-placeholder="$t('sections.rent.heroSection.searchPlaceholder')"
        :search-button="$t('sections.rent.heroSection.searchButton')"
        :is-mobile="getBreakpoints.xs"
        :value="heroInput"
        @input="(e) => handleInput('heroInput', e)"
        @click="
          $router.push({
            name: 'sell-us-process',
            params: {
              address: heroInput,
            },
          })
        "
      />

      <div class="margin-container rent-section-header">
        <SectionHeader
          :title="$t('sections.rent.mainInfoSection.title')"
          :button="$t('sections.rent.mainInfoSection.button')"
          @click="$router.push({ name: 'rent-process' })"
        />
      </div>

      <CardSelectionSection
        class="rent-selection-section"
        :cards="selectionCards"
        :active-card="cardRentType"
        :is-mobile="getBreakpoints.mdAndDown"
        @select="handleRentTypeChange"
      />

      <transition name="section-fade">
        <div
          v-if="rentType === 0"
          :key="'rent-owner'"
        >
          <RentOwnerStepsSection />

          <FeaturesSection
            :title="$t('sections.rent.featuresSection.title')"
            :blue-column-title="
              $t('sections.rent.featuresSection.blueColumnTitle')
            "
            :grey-column-title="
              $t('sections.rent.featuresSection.greyColumnTitle')
            "
            :lines="$t('sections.rent.featuresSection.lines')"
            :button="$t('sections.rent.featuresSection.button')"
            @click="$router.push({ name: 'rent-process' })"
          />

          <!-- <RentOwnerSimulationSection /> -->

          <!-- :button="$t('sections.rent.agentHighlights.button')" -->
          <AgentHighlights
            class="agents-section"
            :agents="agents"
            :title="$t('sections.rent.agentHighlights.title')"
            :subtitle="$t('sections.rent.agentHighlights.subtitle')"
            :is-mobile="getBreakpoints.mdAndDown"
          />

          <!-- <div class="margin-container funnel-section-header">
            <SectionHeader
              :title="$t('sections.rent.ownerFunnelInfoSection.title')"
              :description="$t('sections.rent.ownerFunnelInfoSection.subtitle')"
              :button="$t('sections.rent.ownerFunnelInfoSection.button')"
            />
          </div> -->
        </div>
        <div
          v-else-if="rentType === 1"
          :key="'rent-tenant'"
        >
          <RentTenantStepsSection />

          <!-- <FeaturesSection
            :title="$t('sections.rent.featuresSection.title')"
            :blue-column-title="
              $t('sections.rent.featuresSection.blueColumnTitle')
            "
            :grey-column-title="
              $t('sections.rent.featuresSection.greyColumnTitle')
            "
            :lines="$t('sections.rent.featuresSection.lines')"
            :button="$t('sections.rent.featuresSection.button')"
          /> -->

          <div class="margin-container tenant-cards-section">
            <ImagePanel
              :section="apartmentsSection"
              :is-mobile="getBreakpoints.mdAndDown"
            />
            <ImagePanel
              :section="housesSection"
              reversed
              :is-mobile="getBreakpoints.mdAndDown"
            />
            <ImagePanel
              :section="residencesSection"
              :is-mobile="getBreakpoints.mdAndDown"
            />
          </div>
        </div>
      </transition>

      <ReviewsSection
        :reviews="rentType === 0 ? ownerReviews : tenantReviews"
        :authors="rentType === 0 ? ownerAuthors : tenantAuthors"
        :title="
          rentType === 0
            ? $t('sections.rent.ownerReviewsSection.title')
            : $t('sections.rent.tenantReviewsSection.title')
        "
        :description="
          rentType === 0
            ? $t('sections.rent.ownerReviewsSection.subtitle')
            : $t('sections.rent.tenantReviewsSection.subtitle')
        "
        :is-mobile="getBreakpoints.xs"
      />

      <ReferralSection
        image="referral_placeholder.jpg"
        :title="this.$t('sections.rent.referralSection.title')"
        :description="this.$t('sections.rent.referralSection.description')"
        :primary-button="this.$t('sections.rent.referralSection.button')"
        :outline-button="this.$t('components.imageSection.learnMore')"
        @primaryClick="
          $router.push({
            name: 'referral',
            params: {
              scroll: true,
            },
          })
        "
        @outlineClick="$router.push({ name: 'referral' })"
        :is-mobile="getBreakpoints.smAndDown"
      />
    </template>
  </layout>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  AgentHighlights,
  HeroSection,
  ReferralSection,
  SectionHeader,
  ReviewsSection,
  ImagePanel,
  FeaturesSection,
  CardSelectionSection,
} from 'ff-components-lib';
import Layout from '@/components/layout/Layout';

import RentOwnerStepsSection from '@/components/sections/external/rent/RentOwnerStepsSection';
/* import RentOwnerSimulationSection
from '@/components/sections/external/rent/RentOwnerSimulationSection'; */
import RentTenantStepsSection from '@/components/sections/external/rent/RentTenantStepsSection';

export default {
  name: 'RentScreen',
  components: {
    Layout,
    SectionHeader,
    HeroSection,
    CardSelectionSection,
    RentOwnerStepsSection,
    // RentOwnerSimulationSection,
    RentTenantStepsSection,
    FeaturesSection,
    ReviewsSection,
    ReferralSection,
    AgentHighlights,
    ImagePanel,
  },
  data() {
    return {
      selectionCards: [
        {
          title: this.$t('sections.rent.selectSection.ownerCard.title'),
          icon: 'rentSelection/owner',
        },
        {
          title: this.$t('sections.rent.selectSection.tenantCard.title'),
          icon: 'rentSelection/tenant',
        },
      ],
      cardRentType: 0,
      rentType: 0,
      agents: [
        {
          photo: 'https://storage.googleapis.com/bk-firstfloor-photos/miguel_braga.png',
          name: 'Miguel Braga',
          description: 'Especialista em Vendas',
          rating: 4,
        },
        {
          photo: 'https://storage.googleapis.com/bk-firstfloor-photos/filipa_martins.png',
          name: 'Filipa Martins',
          description: 'Especialista em Rendas',
          rating: 4,
        },
        {
          photo: 'https://storage.googleapis.com/bk-firstfloor-photos/paula_sousa.png',
          name: 'Paula Sousa',
          description: 'Especialista em Rendas',
          rating: 5,
        },
      ],
      heroInput: '',
      ownerReviews: [
        {
          headerText: this.$t('sections.rent.ownerReviewsSection.rentedIn', {
            location: 'em Lisboa',
          }),
          text:
            'Processo simples e com um número reduzido de burocracias. Os inquilinos serem pré verificados ajudou bastante no processo de escolha.',
        },
        {
          headerText: this.$t('sections.rent.ownerReviewsSection.rentedIn', {
            location: 'em Lisboa',
          }),
          text:
            'Deixar o arrendamento do meu imóvel a cargo da FirstFloor foi uma forma eficaz de reduzir uma preocupação de melhor utilizar o meu tempo.',
        },
        {
          headerText: this.$t('sections.rent.ownerReviewsSection.rentedIn', {
            location: 'no Porto',
          }),
          text:
            'Excelente serviço, os profissionais com que lidei foram extremamente prestáveis.',
        },
      ],
      tenantReviews: [
        {
          headerText: this.$t('sections.rent.tenantReviewsSection.rentedIn', {
            location: 'em Lisboa',
          }),
          text:
            'Processo rápido e sem complicações. A comunicação entre todos os intervenientes foi fluida e transmitiu confiança, do início ao fim.',
        },
        {
          headerText: this.$t('sections.rent.tenantReviewsSection.rentedIn', {
            location: 'em Lisboa',
          }),
          text:
            'Arrendar uma casa com apenas um mês de caução seria impossível sem a FirstFloor. O normal, quer seria juntar 3 rendas, teria sido impossível para mim.',
        },
        {
          headerText: this.$t('sections.rent.tenantReviewsSection.rentedIn', {
            location: 'no Porto',
          }),
          text:
            'A verificação previa das casas, informação detalhada e fotos realistas permitem ter confiança no processo de arrendamento, mesmo sem visitas à casa.',
        },
      ],
      ownerAuthors: [
        {
          name: 'João Gouveia',
          location: 'Lisboa',
        },
        {
          name: 'Hugo Morais',
          location: 'Lisboa',
        },
        {
          name: 'Cristina Santos',
          location: 'Lisboa',
        },
      ],
      tenantAuthors: [
        {
          name: 'Mariana Araujo',
          location: 'Lisboa',
        },
        {
          name: 'Gonçalo Cruz',
          location: 'Lisboa',
        },
        {
          name: 'Davide Silva',
          location: 'Porto',
        },
      ],
      apartmentsSection: {
        src: 'apartments.svg',
        title: this.$t('sections.rent.tenantCardsSection.apartmentsTitle'),
        description: this.$t(
          'sections.rent.tenantCardsSection.apartmentsDescription',
        ),
      },
      housesSection: {
        src: 'houses.svg',
        title: this.$t('sections.rent.tenantCardsSection.housesTitle'),
        description: this.$t(
          'sections.rent.tenantCardsSection.housesDescription',
        ),
      },
      residencesSection: {
        src: 'rooms.svg',
        title: this.$t('sections.rent.tenantCardsSection.bedroomsTitle'),
        description: this.$t(
          'sections.rent.tenantCardsSection.bedroomsDescription',
        ),
      },
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  created() {
    if (this.$route.query.rentType) {
      this.cardRentType = Number(this.$route.query.rentType);
      this.rentType = Number(this.$route.query.rentType);
    }
  },
  methods: {
    handleRentTypeChange(e) {
      this.cardRentType = e;
      this.rentType = null;
      setTimeout(() => {
        this.rentType = e;
      }, 480);
    },
    handleInput(field, value) {
      this[field] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.rent-section-header {
  display: flex;
  justify-content: center;
  margin: 120px 0;
}

.rent-selection-section {
  margin-bottom: 192px;
  --card-gap: 189px;
}

.steps-section-wrapper {
  margin-bottom: 120px;
}

.features-section {
  // margin-bottom: 182px;
  margin-bottom: 62px;
}

.tenant-cards-section {
  margin-bottom: 162px;

  display: flex;
  flex-direction: column;
  gap: 120px;

  .image-panel {
    z-index: 10;
  }
}

.rent-simulation-section {
  margin-bottom: 108px;
}

.agents-section {
  margin-bottom: 200px;
}

.funnel-section-header {
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
}

.reviews-section-wrapper {
  margin-bottom: 0;
}

@media only screen and (max-width: 770px) {
  .rent-section-header {
    margin-top: 64px;
    margin-bottom: 62px;
  }

  .rent-selection-section {
    margin-bottom: 62px;
  }

  .steps-section-wrapper {
    margin-bottom: 40px;
  }

  .features-section {
    margin-bottom: 70px;
  }

  .tenant-cards-section {
    margin-bottom: 98px;
  }

  .rent-simulation-section {
    margin-bottom: 0;
  }

  .agents-section {
    margin-bottom: 98px;
  }

  .funnel-section-header {
    margin-bottom: 98px;
  }

  .reviews-section-wrapper {
    margin-bottom: 32px;
  }
}

.section-fade-enter-active,
.section-fade-leave-active {
  transition: all 0.5s;
}
.section-fade-enter,
.section-fade-leave-to {
  opacity: 0;
}
</style>
