var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('template',{slot:"body"},[_c('HeroSection',{attrs:{"image":"agent_banner.jpg","title":_vm.$t('sections.rent.heroSection.title'),"subtitle":_vm.$t('sections.rent.heroSection.subtitle'),"search-placeholder":_vm.$t('sections.rent.heroSection.searchPlaceholder'),"search-button":_vm.$t('sections.rent.heroSection.searchButton'),"is-mobile":_vm.getBreakpoints.xs,"value":_vm.heroInput},on:{"input":function (e) { return _vm.handleInput('heroInput', e); },"click":function($event){return _vm.$router.push({
          name: 'sell-us-process',
          params: {
            address: _vm.heroInput,
          },
        })}}}),_c('div',{staticClass:"margin-container rent-section-header"},[_c('SectionHeader',{attrs:{"title":_vm.$t('sections.rent.mainInfoSection.title'),"button":_vm.$t('sections.rent.mainInfoSection.button')},on:{"click":function($event){return _vm.$router.push({ name: 'rent-process' })}}})],1),_c('CardSelectionSection',{staticClass:"rent-selection-section",attrs:{"cards":_vm.selectionCards,"active-card":_vm.cardRentType,"is-mobile":_vm.getBreakpoints.mdAndDown},on:{"select":_vm.handleRentTypeChange}}),_c('transition',{attrs:{"name":"section-fade"}},[(_vm.rentType === 0)?_c('div',{key:'rent-owner'},[_c('RentOwnerStepsSection'),_c('FeaturesSection',{attrs:{"title":_vm.$t('sections.rent.featuresSection.title'),"blue-column-title":_vm.$t('sections.rent.featuresSection.blueColumnTitle'),"grey-column-title":_vm.$t('sections.rent.featuresSection.greyColumnTitle'),"lines":_vm.$t('sections.rent.featuresSection.lines'),"button":_vm.$t('sections.rent.featuresSection.button')},on:{"click":function($event){return _vm.$router.push({ name: 'rent-process' })}}}),_c('AgentHighlights',{staticClass:"agents-section",attrs:{"agents":_vm.agents,"title":_vm.$t('sections.rent.agentHighlights.title'),"subtitle":_vm.$t('sections.rent.agentHighlights.subtitle'),"is-mobile":_vm.getBreakpoints.mdAndDown}})],1):(_vm.rentType === 1)?_c('div',{key:'rent-tenant'},[_c('RentTenantStepsSection'),_c('div',{staticClass:"margin-container tenant-cards-section"},[_c('ImagePanel',{attrs:{"section":_vm.apartmentsSection,"is-mobile":_vm.getBreakpoints.mdAndDown}}),_c('ImagePanel',{attrs:{"section":_vm.housesSection,"reversed":"","is-mobile":_vm.getBreakpoints.mdAndDown}}),_c('ImagePanel',{attrs:{"section":_vm.residencesSection,"is-mobile":_vm.getBreakpoints.mdAndDown}})],1)],1):_vm._e()]),_c('ReviewsSection',{attrs:{"reviews":_vm.rentType === 0 ? _vm.ownerReviews : _vm.tenantReviews,"authors":_vm.rentType === 0 ? _vm.ownerAuthors : _vm.tenantAuthors,"title":_vm.rentType === 0
          ? _vm.$t('sections.rent.ownerReviewsSection.title')
          : _vm.$t('sections.rent.tenantReviewsSection.title'),"description":_vm.rentType === 0
          ? _vm.$t('sections.rent.ownerReviewsSection.subtitle')
          : _vm.$t('sections.rent.tenantReviewsSection.subtitle'),"is-mobile":_vm.getBreakpoints.xs}}),_c('ReferralSection',{attrs:{"image":"referral_placeholder.jpg","title":this.$t('sections.rent.referralSection.title'),"description":this.$t('sections.rent.referralSection.description'),"primary-button":this.$t('sections.rent.referralSection.button'),"outline-button":this.$t('components.imageSection.learnMore'),"is-mobile":_vm.getBreakpoints.smAndDown},on:{"primaryClick":function($event){return _vm.$router.push({
          name: 'referral',
          params: {
            scroll: true,
          },
        })},"outlineClick":function($event){return _vm.$router.push({ name: 'referral' })}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }